.about-organization {
  &__form {
    border-top: 1px solid #d7dbe0;

  }
  &__left-col {
    border-right: 1px solid #d7dbe0;
    border-bottom: 1px solid #d7dbe0;
    border-left: 1px solid #d7dbe0;
    background-color: #dcecfb;
    padding-left: 20px;
    color: #3e4455;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;

  }
  &__right-col {
    border-bottom: 1px solid #d7dbe0;
    border-right: 1px solid #d7dbe0;
    background-color: #f4f7fb;
    padding: 7px;
  }
  &__input-text {
    height: 47px;
    border-radius: 4px;
    border: 1px solid #d7dbe0;
    background-color: #ffffff;
    width: 100%;
    padding: 0 10px;
  }
}
.organizationInfo__value {
  text-align: left;
  min-height: 50px;
  padding: 12px 22px 13px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.row.m-0:first-child {
  border-top: 1px solid #d7dbe0;
}
