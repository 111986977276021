.documents-page {
  color: #3c4355;
  font-size: 16px;

  &__dates-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__date-input {
    width: 139px;
    height: 47px;
    border-radius: 4px;
    border: 1px solid #d7dbe0;
    background-color: #ffffff;
    padding: 0 10px;
    color: #3c4355;
    font-size: 16px;
    text-align: left !important;
  }

  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      text-align: center;
      padding: 5px 5px;
      color: #3c4355;
      font-size: 16px;
      font-weight: 400;
    }

    tbody {
      tr {
        background-color: #f4f7fb;
        border: 1px solid #d7dbe0;


        td {
          vertical-align: center;
          text-align: center;
          padding: 25px 10px;
          color: #3c4355;
          font-size: 16px;
          font-weight: 400;
          border-top: 1px solid #d7dbe0;
          border-bottom: 1px solid #d7dbe0;

          &:first-child {
            border-left: 1px solid #d7dbe0;
          }

          &:last-child {
            border-right: 1px solid #d7dbe0;
          }
        }
      }
    }
  }

  &__document-title {
    color: #2f65e2;
    font-size: 18px;
    font-weight: 500;
  }
  [data-sort] {
    cursor: pointer;
  }
}