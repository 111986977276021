.lk-upper-nav {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2543a5;
  margin-bottom: 60px;
  &__link {
    color: #ffffff;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    margin: 0 30px;
    border-bottom: 4px solid transparent;
    transition: all .2s;

    &:hover {
      color: #fff;
      border-bottom-color: #9cd1fb;
    }
    &.active {
      color: #9cd1fb;
      border-bottom-color: #9cd1fb;
    }
  }
}