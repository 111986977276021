$height: 47px;

.select2-container--default {
  .select2-selection--single.select2-type-1 {
    height: $height;
    border-radius: 4px;
    border: 1px solid #d7dbe0;
    background-color: #ffffff;

    .select2-selection__rendered {
      line-height: $height;
      color: $shark;
      font-size: 16px;
      font-family: $roboto;
      font-weight: 400;
      padding-right: 35px;
      outline: none;
    }

    .select2-selection__arrow {
      height: $height;
      width: 35px;
      transition: all .2s;

      b {
        width: 18px;
        height: 10px;
        border: none;
        background: url("/img/raster/select-arrow.png") center no-repeat;
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.select2-container--open {
    .select2-selection__arrow {
      transform: rotate(180deg);
      transform-origin: 50%;
    }
  }
}

.select2-container--open {
  .select2-type-1-dropdown {
    &.select2-dropdown--above, &.select2-dropdown--below {
      border-color: #d7dbe0;
      box-shadow:  0 1px 3px rgba(0, 0, 0, 0.24);
      .select2-results__option {
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .select2-results__option--highlighted[aria-selected] {
        background: #dcecfb;
        color: inherit;
      }
    }
  }
}