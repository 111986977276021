.breadcrumbs {
  background-color: #f4f7fb;
  padding: 20px 45px;
  color: #929399;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  align-items: center;
  justify-content: flex-start;


  a {
    color: #929399;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: '/';
      display: inline-block;
      margin: 0 10px;

    }
  }
  span {
    color: #2543a5;
    text-overflow: ellipsis;
    overflow-x: hidden;

  }
}