.pdf-table {
  thead {
    tr {
      th {
        //vertical-align: top;
        background-color: #f7f7f7;
        font-size: 12px;
        padding: 10px 5px;
        vertical-align: middle;
        text-align: center;
      }
    }

  }
  tbody {
    tr {
      td {
        font-size: 12px;
        padding: 10px 5px;
        vertical-align: middle;
        text-align: center;
      }
    }
  }

  .form-table__first-col {
    background-color: #f7f7f7;
    max-width: 20px !important;
    min-width: 20px !important;
  }
}