.login {
  &__section {
    height: 488px;
    background: url('/img/raster/002.png') center no-repeat;
    background-size: cover;
    margin-bottom: 30px;
  }

  &__block {
    width: 435px;
    height: 304px;
    box-shadow: 0 0 58px 23px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    background-color: #dbe8fb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__left-col {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: #1f2543;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.36;
    text-align: center;
    margin-bottom: 20px;
  }
}

.login-form {
  &__row {
    display: flex;
    align-items: center;
  }

  &__label {
    width: 60px;
    display: block;
    text-align: right;
    margin-right: 5px;
    margin-bottom: 0;
    color: #1f2543;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.38;
  }
  &__input {
    height: 47px;
    border-radius: 4px;
    border: 1px solid #d7dbe0;
    background-color: #ffffff;
    padding: 0 10px;
    width: 295px;
    &:focus {
      border: 2px solid #1f4fc2;
      outline: none;
    }
  }
  &__recover-pass-row {
    text-align: right;

  }
  &__recover-pass {
    color: #1f4fc2;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.38;
    text-decoration: underline;
  }
}