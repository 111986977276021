.main-heading {
  color: #1f2543;
  font-size: 35px;
  font-weight: 900;
  line-height: 1.2;
  margin-left: 45px;
  margin-bottom: 0;
}

.butn {
  height: 31px;
  border-radius: 3px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  background-color: transparent;
  transition: all .2s;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  &:active {
    box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.76);
    &.butn_transparent {
      box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.3);
    }
  }

  &_lg {
    height: 48px;
    padding-left: 30px;
    padding-right: 30px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
  }

  &_blue {
    background-color: #1f4fc2;
    color: #fff;

    &:hover {
      background-color: #1e3684;
      color: #fff;
    }
  }

  &_gray {
    background-color: #bababa;
    color: #fff;

    &:hover {
      background-color: darken(#bababa, 10);
      color: #fff;
    }
  }



  &_transparent {
    background: transparent;
    border: 1px solid #b8b8b8;
    color: #1f2543;

    &:hover {
      background-color: lighten(#1e3684, 65);
    }
  }
  &_save {
    width: 150px;
    height: 50px;
  }

  &_w300 {
    width: 300px;
  }
}

.section-title {
  opacity: 0.5;
  color: #1e3684;
  font-size: 18px;
  font-weight: 700;
  line-height: 2.66;
  text-transform: uppercase;
}

.title-blue {
  color: #154ec9;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  margin-bottom: 20px;
}

.page-title {
  color: #1f2543;
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 20px;
}