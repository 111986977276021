.svg-eagle_sm-dims {
	width: 29px;
	height: 28.1px;
}

.svg-email-dims {
	width: 300px;
	height: 300px;
}

.svg-information-dims {
	width: 330px;
	height: 330px;
}

.svg-mail-dims {
	width: 15.012px;
	height: 11.795px;
}

.svg-note-dims {
	width: 25px;
	height: 33.333px;
}

.svg-phone-dims {
	width: 15.012px;
	height: 15.012px;
}

.svg-smartphone-dims {
	width: 300px;
	height: 300px;
}

.svg-spinner-dims {
	width: 25px;
	height: 25px;
}

