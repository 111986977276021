.contacts-page {
  margin-bottom: 60px;

  &__text-col {
    padding-top: 70px;

  }

  &__description {
    margin-bottom: 119px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    //flex: 1 1 100%;
  }

  .svg-smartphone-dims, .svg-email-dims {
    width: 48px;
    height: 48px;
    fill: #c6cce4;
    margin-right: 20px;

  }
  &__link {
    color: #2f65e2;
    font-size: 30px;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
      color: #2f65e2;
    }
  }
}
