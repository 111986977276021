.notifications {
  &__upper-row {
    display: flex;
    align-items: flex-end;
  }

  &__stats {
    color: #3c4355;
    font-size: 16px;
    font-weight: 400;
  }

  &__new {
    color: #2543a5;
    font-weight: 700;
  }

  &__item {
    min-height: 106px;
    border: 1px solid #d7dbe0;
    background-color: #f4f7fb;
    display: flex;
    align-items: center;
    padding: 30px 15px 30px 35px;

    &_new {
      border: 1px solid #d7dbe0;
      background-color: #dcecfb;

      .notifications__title {
        color: #2f65e2;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }

  .svg-note-dims {
    fill: #1f4fc2;
  }

  &__title {
    color: #3e4455;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  &__date {
    color: #3e4455;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;

    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
  }

  &__mark {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    font-family: $robotoCondensed;
    height: 23px;
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 2px;
    background-color: #1f2543;
    cursor: pointer;

    &.active {
      background-color: lighten(#1f2543, 20);
      cursor: auto;
      pointer-events: none;
    }

    &:hover {
      color: #fff;

      &:not(.active) {
        background-color: lighten(#1f2543, 10);
      }
    }
  }
}