.about {

  color: #fff;

  &__title {
    opacity: 0.5;
    color: #1e3684;
    font-size: 18px;
    font-weight: 700;
    line-height: 2.66;
    text-transform: uppercase;
  }

  &__block {
    background-color: #2543a5;
    background-image: url('/img/raster/scientists.png');
    background-position: 35px 50%;
    background-repeat: no-repeat;
    height: 411px;
  }
  &__heading {
    color: #f4f7fb;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 30px;
  }
  &__info-icon {
    width: 109px;
    height: 109px;
    fill: #fff;
    margin-bottom: 70px;
  }
  &__warning {
    color: #d0d0d0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
  }
}