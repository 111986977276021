.sorter {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;

  &__up {
    @include triangle-up(11, 6, #262a2d);
    margin-bottom: 8px;
    &:hover {
      border-bottom-color: #154ec9;

    }
  }

  &__down {
    margin-top: 8px;
    @include triangle-down(11, 6, #262a2d);
    &:hover {
      border-top-color: #154ec9;

    }
  }

  &__up, &__down {
    cursor: pointer;

   
  }
}

.sorting-asc {
  .sorter__up {
    border-bottom-color: #154ec9;
  }
}

.sorting-desc {
  .sorter__down {
    border-top-color: #154ec9;
  }
}