%svg-common {
	background: url("/img/vector/sprite-css.svg") no-repeat;
}

.svg-iko-faq {
	@extend %svg-common;
	background-position: 46.371111905613155% 49.975810353168846%;
}

.svg-iko-faq-dims {
	width: 103.3px;
	height: 103.3px;
}

.svg-logo_nath {
	@extend %svg-common;
	background-position: 99.96671105193077% 0;
}

.svg-logo_nath-dims {
	width: 82.6px;
	height: 103.3px;
}

.svg-nath-1 {
	@extend %svg-common;
	background-position: 66.733708769107% 0;
}

.svg-nath-1-dims {
	width: 134.4px;
	height: 103.3px;
}

.svg-nath-2 {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-nath-2-dims {
	width: 165.9px;
	height: 103.3px;
}

.svg-nath-3 {
	@extend %svg-common;
	background-position: 0 99.95162070633769%;
}

.svg-nath-3-dims {
	width: 103.3px;
	height: 103.3px;
}

.svg-nath-4 {
	@extend %svg-common;
	background-position: 0 49.975810353168846%;
}

.svg-nath-4-dims {
	width: 129.7px;
	height: 103.3px;
}

