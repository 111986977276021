.about-page {
  margin-bottom: 60px;

  &__text-col {
    padding-top: 45px;
  }
  &__list {
    list-style-image: url('/img/raster/arr-right-gray.png');
    padding-left: 30px;
    li {
      padding-left: 10px;
      margin-bottom: 30px;
    }
  }


}