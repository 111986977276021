.footer {
  background-color: #08135d;
  padding: 65px 0;
  color: #fff;

  &__bar {
    height: 60px;
    background-color: #f4f7fb;
  }

  &__title {
    font-size: 21px;
    font-weight: 700;
    line-height: 1.14;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &__descr {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.28;
  }

  &__ministry-row {
    display: flex;
    align-items: center;
  }

  &__ministry-title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 25px;
  }

  &__contacts {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.29;
    margin-bottom: 15px;

  }


  &__contacts-row {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.63;
  }

  &__contacts-icon {
    fill: #7a83bd;
    margin-right: 14px;

  }

  &__contacts-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;

    }
  }
}