.page-heading {
  height: 142px;
  background: #2543a5 url("/img/raster/scientists-2.png") 100% 50% no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 60px;
}