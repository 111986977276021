.actions-block {
  color: #667294;
  font-family: $roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.71;
  text-align: center;
  &__link {
    white-space: nowrap;
    margin: 0 20px;
    fill: #667294;
    svg {
      margin-right: 10px;
    }
  }
  &__share {
    width: 12px;
    height: 15px;
  }
}