.news {
  &__item {
    box-shadow: 0 0 8px rgba(122, 122, 122, 0.35);
    border: 1px solid #d7dbe0;
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 30px 40px;
    display: block;

    &:hover {
      .news__summary {
        text-decoration: underline;
      }
    }
  }

  &__date-row {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__date {
    color: #3c4355;
    font-size: 16px;
    font-weight: 700;
    margin-left: 5px;
  }

  &__title {
    color: #2f65e2;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  &__item-title {
    color: #2f65e2;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.22;
    margin-bottom: 15px;
  }

  &__summary {
    color: #3c4355;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__body {
    color: #3c4355;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 80px;
  }

  &__download-link {
    color: #1f4fc2;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: #1f4fc2;
    }
  }
}