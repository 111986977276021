.datepicker.dropdown-menu {
  font-size: 14px;
}

.datepicker table tr td, .datepicker table tr th {
  width: 38px;
  height: 33px;
  border-radius: 0;
}

.dow {

}