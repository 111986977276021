.react-datepicker__input-container {
  input {
    height: 38px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #d7dbe0;
    padding: 0 10px;
    width: 100px;
  }

}