$jacksonspurple: #1e3684;
$denim: #154ec9;
$royalblue: #2f65e2;
$azureradiance: #007bff;
$black: #000;
$shark: #212529;
$palesky: #6c757d;
$alto: #d3d3d3;
$alabaster: #f7f7f7;
$brightgray: #3b4255;
$comet: #515971;
$waikawagray: #60678e;
$mischka: #d7dbe0;
$blacksqueeze: #f4f7fb;
$alert: #fc0000