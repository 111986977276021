.form-group-item {
  &__list {
    border: 1px solid #d7dbe0;
    background-color: #f4f7fb;
    padding: 50px;
  }
  &__link {
    color: #2543a5;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
  }
  &__status-row {
    color: #212529;
    font-size: 16px;
    font-weight: 700;
  }
  &__status {
    color: #02a3ad;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
  }
  &__link {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &__expand {
    font-size: 12px;
    cursor: pointer;
    color: #2543a5;
    position: relative;
    margin-left: 30px;


    &.clicked {

    }
  }
  &__slider {
    display: none;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  &__loading {
    font-size: 20px;
    position: absolute;
    left: -20px;
    bottom: 0;
    width: 15px;
    display: none;
  }

  &__upload-form {
    &__errors {
      color: $alert;
    }
  }
}