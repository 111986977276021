body {
  font-family: $roboto;
  font-weight: 400;
  font-size: 16px;
  color: #3c4355;
  background-color: #fff;
  min-width: 1280px;
  line-height: 1.2;
}

.section-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 0 15px;
}
.section-bottom-text {
  max-width: 1220px;
  margin: auto;
}

[class*='svg-'] {
  display: inline-block;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  width: 100%;
  background: #fff;
  height: 39px;
  border: 1px solid #d1d1d1;
}

ul {

}

li {
  margin-bottom: 5px;
}

button {
  outline: none;
}

.no-wrap {
  white-space: nowrap;
}

.rotate-180 {
  transform: rotate(180deg);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}


select {
  width: 100%;
}
