.data-forms {

  &__filter {
    padding: 30px 60px;
    border: 1px solid #d7dbe0;
    background-color: #dcecfb;
  }
  &__forms-tbl {
    width: 100%;
    thead {
      th {
        background-color: #dcecfb;
        padding: 20px 5px;
        text-align: center;
        color: #3e4455;
        font-size: 16px;
        font-weight: 700;
      }
    }
    tbody {
      td {
        padding: 20px 5px;
        color: #1f2543;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        input[type=text] {
          border-radius: 4px;
          border: 1px solid #d7dbe0;
          height: 38px;
          padding: 0 10px;
          width: 100%;
        }
      }
    }
  }
  &__link {
    color: #2543a5;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: #2543a5;
    }
  }
  &__input-text {
    height: 47px;
    border-radius: 4px;
    border: 1px solid #d7dbe0;
    background-color: #ffffff;
    padding: 0 10px;
    width: 100px;
  }
}