.main-title {
  background: #fff;
  padding: 40px 0;
  &__ministry {
    display: flex;
    align-items: center;

  }
  &__ministry-title {
    color: #1f2543;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
  }
  &__eagle-logo {
    margin-right: 7px;
    display: inline-block;
  }
}