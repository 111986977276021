.form-table {
  font-size: 14px;
  position: relative;

  .table-wrap{
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: auto;
    margin-bottom: 40px;
  }

  thead {
    th {
      color: #1f2543;
      font-size: 14px;
      font-weight: 600;
      //border: 1px solid #d7dbe0;
      background-color: #dcecfb;
      vertical-align: middle;
      text-align: center;
    }
  }

  tbody {
    tr {
      &.form-table__collection {
        border-collapse: separate;
        border-spacing: 0 10px;
      }
    }

    td {
      padding: 10px 7px;
      position: relative;
      min-width: 100px;
      vertical-align: middle;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid #d7dbe0;
    background-color: #ffffff;
    padding: 5px;
  }

  &__first-col {
    border: 1px solid #d7dbe0;
    background-color: #dcecfb;
    color: #1f2543;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    max-width: 20px !important;
    min-width: 20px !important;
  }

  &__add-btn {
    font-size: 12px;
  }

  &__add-row {
    height: 60px;

    td {
      border: none;
      vertical-align: center;
    }
  }

  &__delete-row {
    color: #fff;
    cursor: pointer;
    position: absolute;
    left: -30px;
    top: calc(50% - 12.5px);
    width: 25px;
    height: 25px;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
    background-color: #1e3685;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;

    &:hover {
      background-color: lighten(#1e3685, 20);
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[disabled] {
    background: #f6f9fb;
    color: #212529;
  }

  &__loading {
    animation: spin 1s ease-in-out infinite;
    fill: #fff;
  }
  &__error {
    font-size: 14px;
    color: red;
    margin-bottom: 20px;
    height: 16px;
    text-align: center;
  }
  &__cell-date {
    width: 75px!important;
  }
  &__cell-textarea {
    min-width: 200px!important;
    textarea {
      border-radius: 4px;
      border: 1px solid #d7dbe0;
      padding: 5px;
    }
  }
  &__cell-select {
    min-width: 180px!important;
  }
  &__cell-checkbox {
    min-width: 150px!important;
    label {
      margin-bottom: 0;
    }
  }

}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}