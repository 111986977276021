.news-block {
  &__inner-wrapper {
    background-color: #f4f7fb;
    padding: 30px 0 50px;
  }

  &__item {
    height: 255px;
    border: 1px solid #d7dbe0;
    background-color: #ffffff;
    padding: 25px;
    display: block;
    transition: all .2s;
    &:hover {
      box-shadow: 0 0 8px rgba(122, 122, 122, 0.35);

    }
  }
  &__date {
    color: #3c4355;
    font-size: 16px;
    font-weight: 700;
    line-height: 2.13;
    margin-bottom: 15px;
  }
  &__item-title {
    color: #154ec9;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.22;
    margin-bottom: 20px;
  }
  &__item-summary {
    color: #3c4355;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
  }
}