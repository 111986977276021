.header {
  &__nav-wrapper {
    background-color: #08135d;

  }

  &__nav {
    color: #ffffff;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    height: 60px;

  }

  &__nav-link {
    padding: 0 30px;
    display: flex;
    height: 100%;
    align-items: center;
    transition: background-color .3s;
    position: relative;
    fill:#fff;

    &:hover {
      background: #2344a1;
    }
    &.active {
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        display: block;
        background: #fc0000;
        height: 2px;
        left: 25px;
        right: 25px;
        top: calc(50% + 12px);
      }
      &.header__nav-link_index {
        &:after {
          top: calc(50% + 20px)
        }
      }

    }
  }

  &__lk-btn {
    height: 33px;
    border-radius: 3px;
    background-color: #2543a5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    margin-left: auto;
    align-self: center;
    transition: all .2s;

    &:hover {
      background: darken(#2543a5, 5);
    }
  }
  &__username {
    border-bottom: 1px dashed #fff;
    color: #dbe8fb;
    font-size: 16px;
    font-weight: 400;
  }
  &__account {
    color: #3968ff;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    &:hover {
        .header__account-dropdown {
          visibility: visible;
          opacity: 1;
          transform: translate(50%, 0);
        }
    }
  }
  &__account-dropdown {
    position: absolute;
    top: 100%;
    right: 50%;
    margin-top: 5px;
    width: 250px;
    padding: 10px 0;
    background: #fff;
    box-shadow:  1px 1px 2px rgba(0, 0, 0, 0.32);
    transition: all .2s;
    transform: translate(50%, -20%);
    visibility: hidden;
    opacity: 0;

  }
  &__account-item {

    padding: 5px 10px;
    display: block;
    &:hover {
      background: #3968ff;
      color: #fff;
    }
  }
}