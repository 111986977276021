.upload-custom {
  display: flex;

  input[type=file] {
    position: absolute;
    height: 0;
    outline: none;
    border: 0;
    width: 0;
    bottom: 0;
    box-shadow: none;
  }

  &__files-list {
    margin-left: 20px;
    padding-top: 15px;
  }

  &__file {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  }
}
