.divTable {
  max-width: 1280px;
  margin: auto;
}

.divTableBody {
  overflow-x: auto;
  width: 100%;
}

.divTableCell {
  display: table-cell;
  border-top: 1px solid #d7dbe0;
  border-bottom: 1px solid #d7dbe0;
  border-left: 1px solid #d7dbe0;

  border-top: none;
  color: rgba(56, 72, 91, 0.8);
  font-size: 14px;
  overflow: overlay;
  vertical-align: middle;

  background-color: #f4f7fb;
  padding: 10px 7px 10px 20px;

  &:last-child {
    border-right: 1px solid #d7dbe0;
  }

  a {
  color: rgba(56, 72, 91, 0.8);
  font-size: 14px;
}
}

.divTableRow {
  display: table-row;
  &--header {
    .divTableCell {

      border-top: 1px solid #d7dbe0;
      border-bottom: 1px solid #d7dbe0;
      border-left: 1px solid #d7dbe0;
      background-color: #dcecfb;
      color: #3e4455;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      width: 130px;
      padding: 13px 11px;

      &:last-child {
        border-right: 1px solid #d7dbe0;
      }

      &--shortName {
        width: 300px;
      }
      &--region {
        width: 250px;
      }
      &--formGroups {
        width: 380px;
      }
    }
  }
}

.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

.beside-simple {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.full-width {
  width: 100%
}