.pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  li {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    background: #f7f7f7;
    cursor: pointer;

    &:hover:not(.current) {
      background: #eeeeee;
    }
    a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .current {
    pointer-events: none;
    color: #fff;
    background: #3b4255;
  }

  .page {

  }

  .next {
  }

  .last {

  }

  .first {

  }

  .previous {

  }
}